import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import BookCard from '../components/Cards/BookCard';

// Consulta para obtener una imagen
const imageQuery = graphql`
query {
  placeholderImage: file(relativePath: { eq: "Portada-La-Vereda-3D.png" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`;

/**
 * Página con el listado de los libros
 */
const Libros = () => {
  const data = useStaticQuery(imageQuery);
  return (
    <Layout>
      <SEO title="Libros" />
      <h3>Libros publicados</h3>
      <BookCard>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        <h5>La vereda del origen</h5>
        <p>
          Una apasionante thriller de asesinatos y conspiraciones
          y bla bla bla.
        </p>
      </BookCard>
      <p></p>
      <Link to="/">Volver al inicio</Link>
    </Layout>
  );
}

export default Libros;
