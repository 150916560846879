import React from 'react';
import styled from 'styled-components';

/**
 * Componente que muestra la tarjeta de un libro.
 */
const BookCard = (props) => {
    // Renderización
    return(
        <Wrapper>
            {props.children}
        </Wrapper>
    );
}

// Componente general de estilo
const Wrapper = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    font-family: var(--ff-poem);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
    margin: 2rem auto;

    :hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    img {
        border-radius: 5px 5px 0 0;
    }

    h5 {
        font-family: var(--ff-title);
        font-size: 20px;
        padding: 2px 16px;
        margin-bottom: 0px;
    }

    p {
        padding: 8px 12px;
        margin-bottom: 0.3rem;
        font-size: 16px;
    }
`;

export default BookCard;